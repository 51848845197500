<template>
  <div>
    <a-form :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="17" :xxl="12">
          <a-form-item>
            <a-select
              show-search
              style="font-size: 20px;"
              size="large"
              allow-clear
              placeholder="请选择任务状态"
              v-decorator="['status', {
                rules: [{ required: true, message: '请选择任务状态' }]
              }]"
            >
              <a-select-option
                v-for="status in taskStatusOptions"
                :key="status.value"
                :value="status.value"
              >
                {{ status.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="7" :xxl="6">
          <a-form-item>
            <div class="custom-flex custom-justify-around ">
              <a-button type="primary" html-type="submit" size="large">
                搜索
              </a-button>
              <a-button @click="handleReset" size="large" style="color: white; background-color: #f59a23;">
                刷新
              </a-button>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
export default {
  name: 'SearchTask',
  data() {
    return {
      taskStatusOptions: [
        { name: '待送达穿衣间', value: 'to_dress_room' },
        { name: '待穿衣', value: 'to_dress' },
        { name: '待送达厅房', value: 'to_hall' },
        { name: '已送到厅房', value: 'hall' }
      ],
      form: this.$form.createForm(this, { name: 'task_search' })
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

