<template>
  <div class="custom-height-100p">
    <a-row :gutter="24" class="custom-height-100p">
      <a-col span="9" class="custom-height-100p custom-flex custom-flex-col">
        <search @submit="submitSearch" />
        <div class="left-box" v-if="data && data.length > 0">
          <a-spin :spinning="loadingData">
            <div
              :class="`custom-card-box custom-margin-bottom-15px ${taskId === task.id ? 'task-active': ''}` "
              v-for="task in data"
              :key="task.id"
              @click="changeTask(task.id)"
            >
              <div class="task-title-box custom-flex custom-justify-between">
                <a-space>
                  <img src="/temp/task_pre.svg">
                  <span class="title">遗体穿衣</span>
                  <div class="status-box">{{ task.status }}</div>
                </a-space>
                <a-button
                  icon="check-circle"
                  style="background-color: #ffd794ff;"
                  size="large"
                  v-if="task.status_slug === 'to_dress_room'"
                  @click="showInputEmpNoModal('to_dress', task.id, '确认送达穿衣间')"
                >
                  确认送达穿衣间
                </a-button>
                <a-button
                  icon="check-circle"
                  style="background-color: #ffd794ff;"
                  size="large"
                  v-if="task.status_slug === 'to_dress'"
                  @click="showInputEmpNoModal('finish_dress', task.id, '完成穿衣')"
                >
                  完成穿衣
                </a-button>
                <a-button
                  icon="check-circle"
                  style="background-color: #ffd794ff;"
                  size="large"
                  v-if="task.status_slug === 'to_hall'"
                  @click="showInputEmpNoModal('confirm_hall', task.id, '确认送达厅房')"
                >
                  确认送达厅房
                </a-button>
              </div>
              <div class="custom-margin-top-10">创建时间：{{ task.created_at }}</div>
              <a-divider />
              <a-row :gutter="12">
                <a-col span="12">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions"
                  >
                    <a-descriptions-item label="逝者">{{ task.death_name }}({{ task.death_sex }})</a-descriptions-item>
                    <a-descriptions-item label="逝世原因">{{ task.death_reason }}</a-descriptions-item>
                    <a-descriptions-item label="进厅时间">
                      <span v-if="task.to_hall_time == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.to_hall_time }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="厅房名称">
                      <span v-if="task.hall_name == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.hall_name }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="寿服名称">
                      <span v-if="task.shroud_name == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.shroud_name }}</span>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
                <a-col span="12">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions-max-7 custom-descriptions"
                  >
                    <a-descriptions-item label="是否有贵重物品">
                      <span class="custom-ellipsis">{{ task.have_valuables ? `有；${task.valuables_desc}` : '无' }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="是否有伤口破损">
                      <span class="custom-ellipsis custom-word-break-all">{{ task.have_damage ? `有；${task.damage_desc}` : '无' }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="出厅时间">
                      <span v-if="task.leave_hall_time == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.leave_hall_time }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="文明棺">
                      <span v-if="task.use_coffin == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.use_coffin }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="遗体SPA">
                      <span v-if="task.body_spa == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.body_spa }}</span>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
              </a-row>
            </div>
          </a-spin>
        </div>
        <no-data class="custom-card-box" :tip="`暂无数据，请使用扫码枪扫描二维码`" v-else />
      </a-col>
      <a-col span="15" class="custom-height-100p">
        <div class="custom-card-box custom-height-100p">
          <div v-if="data && data.length > 0">
            <a-spin :spinning="loadingTask">
              <div class="detail-title">遗体穿衣任务详情</div>
              <a-row :gutter="24">
                <a-col span="8">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions-max-7 custom-descriptions"
                  >
                    <a-descriptions-item label="任务状态">
                      {{ task.status }}
                    </a-descriptions-item>
                    <a-descriptions-item label="任务负责人">
                      {{ task.user_name }}
                    </a-descriptions-item>
                    <a-descriptions-item label="创建时间">
                      {{ task.created_at }}
                    </a-descriptions-item>
                    <a-descriptions-item label="关联订单">
                      {{ task.order_no }}
                    </a-descriptions-item>
                    <a-descriptions-item label="订单负责人">
                      <span class="custom-word-break-all"> {{ task.order_user_name }} / {{ task.order_user_phone_number }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="订单申请人">
                      <span class="custom-word-break-all"> {{ task.applicant_name }} / {{ task.applicant_phone_number }}</span>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
                <a-col span="8">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions-max-7 custom-descriptions"
                  >
                    <a-descriptions-item label="逝者名称">
                      {{ task.death_name }}
                    </a-descriptions-item>
                    <a-descriptions-item label="逝者性别">
                      {{ task.death_sex }}
                    </a-descriptions-item>
                    <a-descriptions-item label="逝者年龄">
                      {{ task.death_age }}
                    </a-descriptions-item>
                    <a-descriptions-item label="逝世原因">
                      {{ task.death_reason }}
                    </a-descriptions-item>
                    <a-descriptions-item label="接运地点">
                      {{ task.origination }}
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
                <a-col span="8">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions-max-9 custom-descriptions"
                  >
                    <a-descriptions-item label="是否有贵重物品">
                      <span>{{ task.have_valuables ? `有` : '无' }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="物品描述" v-if="task.have_valuables">
                      {{ task.valuables_desc }}
                    </a-descriptions-item>
                    <a-descriptions-item label="是否有伤口破损">
                      <span>{{ task.have_damage ? `有` : '无' }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="伤口描述" v-if="task.have_damage">
                      {{ task.damage_desc }}
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
              </a-row>
              <a-divider />
              <a-row>
                <a-col span="8">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions"
                  >
                    <a-descriptions-item label="服务标准">
                      <span v-if="task.package_name == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.package_name }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="进厅时间">
                      <span v-if="task.to_hall_time == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.to_hall_time }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="出厅时间">
                      <span v-if="task.leave_hall_time == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.leave_hall_time }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="文明棺">
                      <span v-if="task.use_coffin == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.use_coffin }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="寿衣名称">
                      <span v-if="task.shroud_name == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.shroud_name }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="寿棺名称">
                      <span v-if="task.coffin_name == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.coffin_name }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="厅房名称">
                      <span v-if="task.hall_name == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.hall_name }}</span>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
                <a-col span="8">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions-max-9 custom-descriptions"
                  >
                    <a-descriptions-item label="妆容要求">
                      <span v-if="task.makeup == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.makeup }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="妆容其他要求">
                      <span v-if="task.makeup_others == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.makeup_others }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="遗体清洗">
                      <span v-if="task.clean == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.clean }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="遗体SPA">
                      <span v-if="task.body_spa == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.body_spa }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="遗体清洗其他要求">
                      <span v-if="task.clean_others == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.clean_others }}</span>
                    </a-descriptions-item>
                  </a-descriptions></a-col>
                <a-col span="8">
                  <a-descriptions
                    :column="1"
                    class="custom-descriptions-max-7 custom-descriptions"
                  >
                    <a-descriptions-item label="其他服务项目">
                      <span v-if="task.other_service == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.other_service }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="其他配件物品">
                      <span v-if="task.other_goods == '待确定'" class="custom-green">待确定</span>
                      <span v-else>{{ task.other_goods }}</span>
                    </a-descriptions-item>
                    <a-descriptions-item label="穿衣入殓时间">
                      <span v-if="task.start_time == '待确定'" class="custom-green">待确定</span>
                      <span v-else>
                        <div v-if="task.start_time && task.end_time">
                          {{ task.start_time }} ~ {{ task.end_time }}
                        </div>
                      </span>
                    </a-descriptions-item>
                    <a-descriptions-item label="备注">
                      {{ task.remark }}
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
              </a-row>
              <div class="custom-flex custom-flex-col custom-items-end">
                <div style="margin-bottom: 10px;">
                  <a-button
                    style="background-color: #ffd794ff;"
                    size="large"
                    @click="showInputEmpNoModal('to_dress', taskId, '确认送达穿衣间')"
                    v-if="task.status_slug === 'to_dress_room'"
                  >
                    确认送达穿衣间
                  </a-button>
                </div>
                <div style="margin-bottom: 10px;">
                  <a-button
                    style="width: 155px; background-color: #ffd794ff;"
                    size="large"
                    @click="showInputEmpNoModal('finish_dress', taskId, '完成穿衣')"
                    v-if="task.status_slug === 'to_dress'"
                  >
                    完成穿衣
                  </a-button>
                </div>

                <div>
                  <a-button
                    style="width: 155px; background-color: #ffd794ff;"
                    size="large"
                    @click="showInputEmpNoModal('confirm_hall', taskId, '确认送达厅房')"
                    v-if="task.status_slug === 'to_hall'"
                  >
                    确认送达厅房
                  </a-button>
                </div>
              </div>
            </a-spin>
          </div>
          <no-data :tip="`暂无数据，请使用扫码枪扫描二维码`" v-else />
        </div>
      </a-col>
    </a-row>

    <InputEmpNo
      :title="inputEmpNoTitle"
      v-if="isShowInputEmpNoModal"
      :visible.sync="isShowInputEmpNoModal"
      @completed="handleOperate"
    />
  </div>
</template>

<script>
import { findDressTasks, findDressTask, updateDressTaskStatus } from '@/api/task'
import Search from '@/views/home/dress_screen/Search'
import NoData from '@/views/home/NoData'
export default {
  name: 'DressScreen',
  components: {
    Search,
    NoData,
    InputEmpNo: () => import('@/views/home/InputEmpNo')
  },
  data() {
    return {
      data: [],
      query: {},
      taskId: 0,
      task: {},
      loadingData: true,
      loadingTask: true,
      inputEmpNoTitle: '',
      isShowInputEmpNoModal: false,
      operate: '',
      operateTaskId: 0
    }
  },
  watch: {
    taskId(val) {
      this.taskId = val
      this.fetchData(false)
      this.fetchTask()
    }
  },
  created() {
    this.fetchData(true)
    this.loopFetchData()
  },
  destroyed() {
    clearInterval(this.loopFetchDataTimer)
  },
  methods: {
    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.loopRefreshData, 60000)
    },

    loopRefreshData() {
      if (this.data && this.data.length > 0) {
        this.fetchData(false)
      } else {
        this.fetchData(true)
      }
      this.fetchTask()
    },

    fetchData(isRefreshTaskId) {
      this.loadingData = true
      if (isRefreshTaskId) {
        this.taskId = 0
      }
      findDressTasks(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          if (isRefreshTaskId) {
            if (this.data && this.data.length > 0) {
              this.taskId = this.data[0].id
            }
          }
        }
      })
      this.loadingData = false
    },

    fetchTask() {
      this.loadingTask = true
      this.task = {}
      if (this.taskId > 0) {
        findDressTask(this.taskId).then((res) => {
          if (res.code === 0) {
            this.task = res.data
          }
        })
      }
      this.loadingTask = false
    },

    changeTask(taskId) {
      this.taskId = taskId
    },

    showInputEmpNoModal(operate, operateTaskId, title) {
      this.operate = operate
      this.operateTaskId = operateTaskId
      this.inputEmpNoTitle = title
      this.isShowInputEmpNoModal = true
    },

    handleOperate(value) {
      let targetStatus
      if (this.operate === 'finish_dress') {
        targetStatus = 'to_hall'
      } else if (this.operate === 'confirm_hall') {
        targetStatus = 'hall'
      } else if (this.operate === 'to_dress') {
        targetStatus = 'to_dress'
      } else {
        return
      }
      updateDressTaskStatus(
        this.operateTaskId, Object.assign({}, value, { target_status: targetStatus })
      ).then((res) => {
        if (res.code === 0) {
          if (['to_dress', 'confirm_hall'].includes(this.operate)) {
            this.fetchData(true)
          } else {
            this.loopRefreshData()
          }
        }
      })
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData(true)
    }
  }
}
</script>

<style lang="less" scoped>
.left-box {
  overflow: auto;
}

.status-box {
  padding: 0 5px;
  border-radius: 100px;
  color: #333;
  background-color: #d8eeeb;
}

.task-title-box {
  .title {
    font-size: 24px;
  }
}

.task-active {
  border: 3px solid #ffd593;
}

.detail-title {
  display: flex;
  align-items: center;
  height: 100px;
  font-size: 24px;
}
</style>
